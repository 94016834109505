import Image from 'next/image';
import { Card, CardContent } from '@/components/ui/card';
import { Image as ImageType } from '@/types/types';
interface TestimonialCardProps {
	company_logo: ImageType;
	name: string;
	company_name: string;
	testimonial_text: string;
}

export function TestimonialCard({
	company_logo,
	name,
	company_name,
	testimonial_text,
}: TestimonialCardProps) {
	return (
		<Card className="group h-[240px] overflow-hidden shadow-sm transition-all hover:shadow-md">
			<CardContent className="flex h-full flex-col p-2">
				<div className="flex items-center">
					{/* Company logo */}
					<div className="mr-3 flex h-24 w-24 flex-shrink-0 items-center justify-center overflow-hidden bg-white">
						<Image
							src={company_logo.url || '/placeholder.svg'}
							alt={`${company_name} logo`}
							width={96}
							height={96}
							className="object-contain"
							style={{
								objectFit: 'contain',
								objectPosition: 'center',
							}}
						/>
					</div>

					<div className="flex flex-col">
						<h5 className="text-lg font-bold text-gray-900">
							{company_name}
						</h5>
						<h6 className="text-sm font-medium text-gray-600">
							@{name}
						</h6>
					</div>
				</div>

				<div className="relative flex-1 overflow-hidden pl-6">
					<svg
						className="absolute left-0 top-0 h-4 w-4 text-gray-400 transition-colors duration-300 group-hover:text-blue-500"
						fill="currentColor"
						viewBox="0 0 24 24">
						<path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
					</svg>

					<div className="testimonial-text-container max-h-[160px] overflow-y-auto transition-all duration-300">
						<p className="pb-2 leading-relaxed text-gray-700">
							{testimonial_text}
						</p>
					</div>

					{/* Gradient fade for long text */}
					<div className="pointer-events-none absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-t from-white to-transparent transition-opacity duration-300 group-hover:opacity-0"></div>
				</div>
			</CardContent>
		</Card>
	);
}
