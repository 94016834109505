import { useEffect, useRef, useState } from 'react';
import { TestimonialCard } from '@/components/testimonial-card/TestimonialCard';
import { Image } from '@/types/types';

interface TestimonialsSectionProps {
	heading?: string;
	sub_heading?: string;
	testimonials?: Array<{
		company_logo: Image;
		name: string;
		company_name: string;
		testimonial_text: string;
	}>;
}

// Define testimonial type for proper typing
type Testimonial = {
	company_logo: Image;
	name: string;
	company_name: string;
	testimonial_text: string;
};

export function TestimonialsSection({
	heading = 'Step Into The Exclusive Circle of Global Business Titans',
	sub_heading,
	testimonials = [],
}: TestimonialsSectionProps) {
	const [isVisible, setIsVisible] = useState(false);
	const [animationComplete, setAnimationComplete] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [screenSize, setScreenSize] = useState('large'); // 'small', 'medium', or 'large'
	const sectionRef = useRef<HTMLElement>(null);

	// Set initial screen size and update on resize
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setScreenSize('small');
			} else if (window.innerWidth < 1024) {
				setScreenSize('medium');
			} else {
				setScreenSize('large');
			}
		};

		// Set initial size
		handleResize();

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Determine the number of items per slide based on screen size
	const getItemsPerSlide = () => {
		if (screenSize === 'small') return 1;
		if (screenSize === 'medium') return 2;
		return 3; // large screens
	};

	// Calculate the total number of slides based on screen size
	const totalSlides =
		testimonials.length > 0
			? Math.ceil(testimonials.length / getItemsPerSlide())
			: 0;

	// Handle navigation
	const goToNextSlide = () => {
		setCurrentIndex(prevIndex =>
			prevIndex === totalSlides - 1 ? 0 : prevIndex + 1,
		);
	};

	const goToPrevSlide = () => {
		setCurrentIndex(prevIndex =>
			prevIndex === 0 ? totalSlides - 1 : prevIndex - 1,
		);
	};

	// Auto-advance slides every 5 seconds
	useEffect(() => {
		const timer = setInterval(goToNextSlide, 5000);
		return () => clearInterval(timer);
	}, [totalSlides]);

	// Get current visible testimonials based on screen size
	const getVisibleTestimonials = () => {
		const itemsPerSlide = getItemsPerSlide();
		return testimonials.slice(
			currentIndex * itemsPerSlide,
			currentIndex * itemsPerSlide + itemsPerSlide,
		);
	};

	// Get current visible testimonials and pad if needed
	const visibleTestimonials = getVisibleTestimonials();
	const paddedVisibleTestimonials: Array<Testimonial | null> = [
		...visibleTestimonials,
	];

	// Pad with empty slots based on screen size
	while (paddedVisibleTestimonials.length < getItemsPerSlide()) {
		paddedVisibleTestimonials.push(null);
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				// When the section enters the viewport
				if (entries[0].isIntersecting) {
					setIsVisible(true);
					observer.disconnect(); // Disconnect once animation is triggered

					// Set animation complete after all staggered animations have finished
					const maxDelay = 1500;
					setTimeout(() => {
						setAnimationComplete(true);
					}, maxDelay);
				}
			},
			{
				root: null, // Use viewport as root
				threshold: 0.2, // Trigger when 20% of the element is visible
			},
		);

		if (sectionRef.current) {
			observer.observe(sectionRef.current);
		}

		return () => {
			if (sectionRef.current) {
				observer.unobserve(sectionRef.current);
			}
		};
	}, []);

	// Reset animation state when slide changes
	useEffect(() => {
		// Reset animation state briefly
		setAnimationComplete(false);

		// Re-enable after animation duration
		const timer = setTimeout(() => {
			setAnimationComplete(true);
		}, 800);

		return () => clearTimeout(timer);
	}, [currentIndex]);

	// Reset current slide when screen size changes to prevent out-of-bounds slides
	useEffect(() => {
		setCurrentIndex(0);
	}, [screenSize]);

	// Calculate animation directions for each card based on index
	const getAnimationStyles = (index: number) => {
		// Different animation based on number of items per slide
		const itemsPerSlide = getItemsPerSlide();

		// Create different animation directions based on position and screen size
		let initialTransform = '';

		if (itemsPerSlide === 1) {
			// Small screen - animate from bottom
			initialTransform = 'translateY(40px)';
		} else if (itemsPerSlide === 2) {
			// Medium screen - left and right animations
			initialTransform =
				index % 2 === 0
					? 'translateX(-40px)' // First card from left
					: 'translateX(40px)'; // Second card from right
		} else {
			// Large screen - left, top, right animations
			if (index === 0) {
				initialTransform = 'translateX(-40px)'; // First card from left
			} else if (index === 1) {
				initialTransform = 'translateY(-40px)'; // Middle card from top
			} else {
				initialTransform = 'translateX(40px)'; // Last card from right
			}
		}

		// Add a scale effect for all items
		initialTransform += ' scale(0.92)';

		// Custom delay based on position
		const delay = index * 150;

		return {
			opacity: isVisible ? 1 : 0,
			transform: isVisible ? 'translateY(0) scale(1)' : initialTransform,
			transitionDelay: `${delay}ms`,
			transitionDuration: '600ms',
		};
	};

	return (
		<section
			ref={sectionRef}
			className={`bg-gray-50 py-8 transition-all duration-700 ease-out ${
				isVisible ? 'opacity-100' : 'opacity-0'
			} ${animationComplete ? 'hover:bg-gray-100' : ''}`}>
			<div className="container mx-auto px-3 sm:px-4 lg:px-6">
				{/* Heading with reveal animation */}
				<div
					className={`mx-auto mb-6 max-w-3xl text-center transition-all duration-700 ease-out ${
						isVisible
							? 'translate-y-0 opacity-100'
							: 'translate-y-10 opacity-0'
					}`}>
					<h2 className="mb-3 text-3xl font-bold text-gray-900 transition-all duration-500">
						{heading}
					</h2>
					{sub_heading && (
						<p
							className="text-lg text-gray-600 transition-all duration-700"
							style={{
								transitionDelay: '200ms',
								opacity: isVisible ? 1 : 0,
								transform: isVisible
									? 'translateY(0)'
									: 'translateY(15px)',
							}}>
							{sub_heading}
						</p>
					)}
				</div>

				{/* Testimonial Carousel */}
				<div className="relative">
					<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
						{paddedVisibleTestimonials.map((testimonial, index) => (
							<div
								key={`slide-${currentIndex}-item-${index}`}
								className={`testimonial-item h-[280px] ${!testimonial ? 'hidden' : ''}`}
								style={
									testimonial
										? getAnimationStyles(index)
										: { opacity: 0 }
								}>
								{testimonial && (
									<TestimonialCard {...testimonial} />
								)}
							</div>
						))}
					</div>

					{/* Navigation buttons */}
					{totalSlides > 1 && (
						<div className="mt-6 flex justify-center space-x-4">
							<button
								onClick={goToPrevSlide}
								className="flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md transition-all hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
								aria-label="Previous testimonials">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-gray-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15 19l-7-7 7-7"
									/>
								</svg>
							</button>

							{/* Pagination indicators */}
							<div className="flex items-center space-x-2">
								{Array.from({ length: totalSlides }).map(
									(_, index) => (
										<button
											key={`pagination-${index}`}
											onClick={() =>
												setCurrentIndex(index)
											}
											className={`h-2.5 rounded-full transition-all ${currentIndex === index ? 'w-5 bg-blue-500' : 'w-2.5 bg-gray-300'}`}
											aria-label={`Go to slide ${index + 1}`}
										/>
									),
								)}
							</div>

							<button
								onClick={goToNextSlide}
								className="flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md transition-all hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
								aria-label="Next testimonials">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6 text-gray-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M9 5l7 7-7 7"
									/>
								</svg>
							</button>
						</div>
					)}
				</div>
			</div>

			<style jsx>{`
				.testimonial-item {
					transition:
						opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1),
						transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
				}

				@media (prefers-reduced-motion: reduce) {
					.testimonial-item {
						transition: opacity 0.5s ease-out;
						transform: none !important;
					}
				}
			`}</style>
		</section>
	);
}
